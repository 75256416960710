<template>
  <div class="stripe-checkout-wrap">
    <div class="sticky-header is-flex is-justify-content-center is-align-items-center has-background-white">
      <img src="@assets/images/common/logo.png" alt="logo" class="logo" @click="toDashboard">
    </div>
    <section class="additional-info mt-1" v-if="showBillingInfo">
      <div class="section-title has-text-weight-semibold mb-5 has-text-centered">Billing Info</div>
      <b-field label="Full Name">
        <b-input placeholder="John Doe"
          type="text"
          custom-class="is-medium-height"
          required
          v-model.trim="fullName"
          validation-message="Name is required.">
        </b-input>
      </b-field>
      <b-field label="Address">
        <b-input placeholder="1 - 123 Main Street"
          type="text"
          custom-class="is-medium-height"
          required
          v-model.trim="streetNumber"
          validation-message="Address is required.">
        </b-input>
      </b-field>
      <b-field label="City">
        <b-input placeholder="Vancouver"
          type="text"
          custom-class="is-medium-height"
          required
          v-model.trim="city"
          validation-message="City is required.">
        </b-input>
      </b-field>
      <b-field label="Country">
        <b-select placeholder="Select country" class="is-medium-height is-blue" required expanded v-model="country">
          <option value="CA">Canada</option>
          <option value="US">United States</option>
        </b-select>
      </b-field>
      <b-field label="Postal code">
        <b-input placeholder="M1N 2B4"
          type="text"
          custom-class="is-medium-height"
          required
          v-model.trim="postalCode"
          validation-message="Postal code is required.">
        </b-input>
      </b-field>
      <PromiseLoadingButton
        :action="updateBillingAddress"
        :disabled="!city || !streetNumber || !country || !postalCode || !fullName"
        :hasBoxShadow="false"
        class="button has-text-weight-semibold has-background-primary is-medium-height is-fullwidth mt-6 mb-5"
      >Next</PromiseLoadingButton>
    </section>
    <div class="section-title has-text-weight-semibold mb-6 has-text-centered" v-if="showPayButton">Payment Info</div>
    <form id="payment-form">
      <div id="card-element">
        <!-- Stripe Elements will create form elements here -->
      </div>
      <PromiseLoadingButton
        v-if="showPayButton"
        :action="pay"
        :disabled="nextDisabled"
        :hasBoxShadow="false"
        class="button has-text-weight-semibold has-background-primary is-medium-height is-fullwidth mt-6 mb-5"
      >Pay ${{totalAmount | formatAmount}}</PromiseLoadingButton>
    </form>
    <ConfirmModal
      title="Payment Failed"
      :text="failureModalText"
      :showModal="showFailureModal"
      @closeModal="showFailureModal = false"
    ></ConfirmModal>
  </div>
</template>
<script>
import PromiseLoadingButton from '@components/button/promise-loading-button.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import { loadStripe } from '@stripe/stripe-js'
import { formatter } from '@utils/common-methods/numberFormatter'
import { createStripeOrder, submitStripeOrder } from '@api/membership'
import camelize from 'camelize'

export default {
  data() {
    return {
      stripe: null,
      elements: null,
      cardElement: null,
      showPayButton: false,
      publishableKey: process.env.VUE_APP_PUBLISHABLE_KEY,
      nextDisabled: true,
      totalAmount: 0,
      clientSecret: '',
      showBillingInfo: true,
      fullName: '',
      city: undefined,
      streetNumber: undefined,
      country: undefined,
      postalCode: undefined,
      showFailureModal: false,
      failureModalText: '',
    }
  },
  computed: {
    membershipType() {
      return this.$route.params.type
    },
    chargeType() {
      return this.membershipType === 'accredited' ? 'accredited_membership' : 'addyone_membership'
    },
    returnUrl() {
      return this.$route.query.returnUrl
    },
  },
  components: {
    PromiseLoadingButton,
    ConfirmModal,
  },
  mounted() {
    this.initializeStripe()
  },
  filters: {
    formatAmount(value, decimals = 2) {
      return formatter(value, decimals)
    },
  },
  methods: {
    async initializeStripe() {
      this.stripe = await loadStripe(this.publishableKey, {
        betas: ['process_order_beta_1'],
        apiVersion: '2017-08-15; orders_beta=v4'
      })

      createStripeOrder(this.chargeType).then((data) => {
        if (!data.success) return
        const { clientSecret } = data.data
        this.clientSecret = clientSecret
      })
    },
    async updateBillingAddress() {
      await this.stripe.updateOrder({
        clientSecret: this.clientSecret,
        params: {
          billing_details: {
            name: this.fullName,
            address: {
              postal_code: this.postalCode,
              country: this.country,
              city: this.city,
              line1: this.streetNumber,
            }
          },
        }
      })

      const { order } = await this.stripe.retrieveOrder(this.clientSecret)
      const { id, amountTotal } = camelize(order)
      this.totalAmount = amountTotal / 100

      const result = await submitStripeOrder({
        order_id: id,
        expected_total: amountTotal,
      })
      if (!result.success) return
      const { clientSecret } = result.data
      this.clientSecret = clientSecret
      this.initPaymentElement(clientSecret)
    },
    initPaymentElement(clientSecret) {
      const appearance = {
        theme: 'flat',
        variables: {
          fontFamily: ' "Calibre", sans-serif',
          fontLineHeight: '1.5',
          fontSizeSm: '14px',
          borderRadius: '12px',
          colorBackground: '#F6F8FA',
          colorPrimaryText: '#262626',
          colorPrimary: '#4A26AA',
        },
        rules: {
          '.Label': {
            fontWeight: '600'
          },
        },
      }
      this.elements = this.stripe.elements({ appearance, clientSecret })
      const cardElement = this.elements.create('card', {
        hidePostalCode: true,
      })
      this.cardElement = cardElement
      this.showBillingInfo = false
      cardElement.mount('#card-element')

      cardElement.on('ready', () => {
        this.showPayButton = true
      })
      cardElement.on('change', (event) => {
        if (event.complete) this.nextDisabled = false
        else this.nextDisabled = true
      })
    },
    async pay() {
      const result = await this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method: {
          card: this.cardElement,
          billing_details: {
            name: this.fullName,
            address: {
              postal_code: this.postalCode,
              country: this.country,
              city: this.city,
              line1: this.streetNumber,
            }
          },
        }
      })
      if (result.error) {
        this.failureModalText = result.error.message
        this.showFailureModal = true
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          this.$router.push(`/membership/success?returnUrl=${this.returnUrl}&type=${this.membershipType}`)
        }
      }
    },
    toDashboard() {
      this.$router.push('/dashboard')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.stripe-checkout-wrap {
  padding: 0 24px;
  .sticky-header {
    height: 68px;
    margin-bottom: 20px;
    .logo {
      width: 63px;
    }
  }
  .section-title {
    font-size: 20px;
  }
  .additional-info {
    .field {
      margin-bottom: 12px;
      ::v-deep .label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }
    }
  }
  .button {
    border-radius: 14px;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .stripe-checkout-wrap {
    padding-top: 30px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
  }
}
</style>
